































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import copy from 'copy-to-clipboard'
import {Instructor} from '~/components/data-class/data-class'
import InstructorsData from '~/components/instructor/instructors-data'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {},
    metaInfo() {
        return {
            title: 'Tutor List'
        }
    }
})
export default class InstructorList extends Vue {
    static permission = AdminPermission.Instructor

    instructors: Instructor[] = []
    async created() {
        await this.getInstructors()
    }
    async getInstructors() {
        await InstructorsData.update()
        this.instructors = InstructorsData.instructors
    }
    async openInstructor(id: string) {
        await this.$router.push('/instructor/' + id)
    }
    async showChanged(instructor: Instructor) {
        await createRequest('/instructor/' + instructor.member_id + '/profile', 'patch', {}, {show: instructor.show}).send()
        // await this.getInstructors()
        this.$message({
            message: 'Changed!',
            type: 'success'
        })
    }
    async ownChanged(instructor: Instructor) {
        await createRequest('/instructor/' + instructor.member_id + '/profile', 'patch', {}, {own_instructor: instructor.own_instructor}).send()
        // await this.getInstructors()
        this.$message({
            message: 'Changed!',
            type: 'success'
        })
    }

    copyInstructor(id: string, urlName: string) {
        const str = 'https://afterschool.com.hk/instructor/' + id + '/' + urlName + '?code=' + urlName

        copy(str, {
            debug: true,
            message: 'Press #{key} to copy',
        })

        this.$message('Coped!!')

    }

}


