var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[_c('el-main',[_c('el-table',{attrs:{"stripe":"","data":_vm.instructors}},[_c('el-table-column',{attrs:{"prop":"_id","label":"ID","align":"center","width":"60px"}}),_c('el-table-column',{attrs:{"prop":"display_name","label":"Display Name","align":"center","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('member-popover',{attrs:{"member-id":row.member_id}},[_vm._v(_vm._s(row.display_name))])]}}])}),_c('el-table-column',{attrs:{"prop":"subjects","label":"Subject(s) Taught"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.subjects),function(sub,subIndex){return _c('span',[_vm._v(" "+_vm._s(sub)+" "),(subIndex !== row.subjects.length-1)?_c('span',[_vm._v(",")]):_vm._e()])})}}])}),_c('el-table-column',{attrs:{"label":"Own Tutor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#AAAAAA"},on:{"input":function($event){return _vm.ownChanged(row)}},model:{value:(row.own_instructor),callback:function ($$v) {_vm.$set(row, "own_instructor", $$v)},expression:"row.own_instructor"}})]}}])}),_c('el-table-column',{attrs:{"label":"Show"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#AAAAAA"},on:{"input":function($event){return _vm.showChanged(row)}},model:{value:(row.show),callback:function ($$v) {_vm.$set(row, "show", $$v)},expression:"row.show"}})]}}])}),_c('el-table-column',{attrs:{"width":"190px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"block"},[_c('el-button',{attrs:{"type":"primary","size":"small","plain":""},on:{"click":function($event){return _vm.openInstructor(row._id)}}},[_vm._v(" Open ")]),_c('el-button',{attrs:{"type":"primary","size":"small","plain":""},on:{"click":function($event){return _vm.copyInstructor(row._id, row.url_name)}}},[_vm._v(" Copy Link ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }